<template>
  <div class="min-h-screen bg-white relative">
    <div class="p-6">
      <!-- Task List -->
      <div class="mt-6">
        <div class="flex justify-between">
          <div>
            <h2 class="text-lg font-semibold">
              {{ selectedTab.name }}'s Tasks
            </h2>
            <p class="text-sm text-gray-500">{{ selectedTab.date }}</p>
          </div>
          <!-- New Task Button -->
          <button
            class="bg-[#e2ebfa] text-[#034afc] h-10 px-4 rounded-lg text-sm focus:outline-none"
            @click="openModal"
          >
            + New Task
          </button>
        </div>

        <!-- Task Card -->
        <div
          v-for="task in filteredTasks"
          :key="task.id"
          class="mt-4 p-6 bg-gray-100 rounded-2xl flex items-center justify-between cursor-pointer"
          @click="toggleTaskCompletion(task.id)"
        >
          <div class="flex items-center space-x-2">
            <!-- Task Status -->
            <span
              class="w-8 h-8 border text-white rounded-full flex items-center justify-center text-xs"
              :class="task.isCompleted ? 'bg-green-500' : 'border-black'"
            >
              <span v-if="task.isCompleted" class="text-white text-xl">
                &#10003;
              </span>
            </span>
          </div>
          <div>
            <p
              :class="
                task.isCompleted ? 'text-gray-400 line-through' : 'text-black'
              "
              class="text-lg"
            >
              {{ task.title }}
            </p>
            <p class="text-sm text-gray-500">{{ task.time }}</p>
          </div>
        </div>
      </div>

      <!-- Tab Navigation -->
      <div class="fixed left-0 bottom-32 w-full">
        <div class="flex justify-around">
          <button
            v-for="tab in tabs"
            :key="tab"
            @click="selectedTab = tab"
            :class="
              selectedTab.name === tab.name
                ? 'font-bold text-black'
                : 'text-gray-400'
            "
            class="focus:outline-none"
          >
            {{ tab.name }}
          </button>
        </div>
      </div>

      <!-- Refresh Button -->
      <div class="fixed left-0 bottom-10 w-full flex justify-center">
        <button
          @click="refreshTasks"
          class="bg-black text-white text-3xl w-14 h-14 rounded-full flex items-center justify-center focus:outline-none"
        >
          &#x21bb;
        </button>
      </div>
    </div>

    <!-- New Task Modal -->
    <div
      v-if="showModal"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-end"
      @click.self="closeModal"
    >
      <div
        class="bg-white w-full p-6 pb-[10vw] rounded-t-xl shadow-lg transform transition-all duration-500"
      >
        <h2 class="text-lg font-bold mb-4">Add New Task</h2>
        <input
          type="text"
          v-model="newTaskTitle"
          ref="taskInput"
          @keydown.enter="addNewTask"
          placeholder="Task Title"
          class="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { nextTick } from "vue";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

// Set up Laravel Echo with Pusher
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "2cebcefb627c1ac098a5", // Replace with your Pusher key
  cluster: "eu", // Replace with your Pusher cluster
  encrypted: true,
});

export default {
  data() {
    return {
      selectedTab: {
        name: "Today",
        date: new Date().toISOString().split("T")[0],
      },
      showModal: false,
      newTaskTitle: "",
      tabs: [
        {
          name: "Yesterday",
          date: new Date(Date.now() - 864e5).toISOString().split("T")[0],
        },
        {
          name: "Today",
          date: new Date().toISOString().split("T")[0],
        },
        {
          name: "Tomorrow",
          date: new Date(Date.now() + 864e5).toISOString().split("T")[0],
        },
      ],
      tasks: {}, // Will store tasks grouped by date
    };
  },
  computed: {
    filteredTasks() {
      return this.tasks[this.selectedTab.date] || [];
    },
  },
  methods: {
    async openModal() {
      this.showModal = true;
      nextTick(() => {
        this.$refs.taskInput.focus();
      });
    },
    closeModal() {
      this.showModal = false;
    },
    async addNewTask() {
      if (this.newTaskTitle) {
        const newTask = {
          id: Math.floor(Math.random() * 1000),
          title: this.newTaskTitle,
          start: `${this.selectedTab.date} 10:00`,
          end: `${this.selectedTab.date} 11:00`,
          isCompleted: false,
        };

        const data = {
          [this.selectedTab.date]: [newTask],
        };

        try {
          // Send the new task to the API
          await axios.post("https://todo-api.g-home.site/api/v2/todos", data);
          this.refreshTasks(); // Reload tasks after adding
        } catch (error) {
          console.error("Failed to add new task", error);
        }

        this.newTaskTitle = "";
        this.closeModal();
      }
    },
    async toggleTaskCompletion(taskId) {
      const task = this.filteredTasks.find((task) => task.id === taskId);
      task.isCompleted = !task.isCompleted;

      try {
        await axios.patch(
          `https://todo-api.g-home.site/api/v2/todos/${taskId}`,
          task
        );
        this.refreshTasks(); // Reload tasks after toggling completion
      } catch (error) {
        console.error("Failed to update task", error);
      }
    },
    async refreshTasks() {
      try {
        // Fetch tasks from the API grouped by date
        const response = await axios.get(
          "https://todo-api.g-home.site/api/v2/todos"
        );
        this.tasks = response.data;
      } catch (error) {
        console.error("Failed to load tasks", error);
      }
    },
    listenForTaskEvents() {
      window.Echo.channel("tasks").listen("TaskCreated", () => {
        this.refreshTasks();
      });
      window.Echo.channel("tasks").listen("TaskUpdated", () => {
        this.refreshTasks();
      });
      window.Echo.channel("tasks").listen("TaskDeleted", () => {
        this.refreshTasks();
      });
    },
  },
  async mounted() {
    await this.refreshTasks(); // Load tasks when the component is mounted
    this.listenForTaskEvents(); // Set up real-time listeners
  },
};
</script>

<style scoped>
body {
  font-family: "Inter", sans-serif;
}
</style>
